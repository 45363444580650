.TitleIndustries{
  max-width: 65%; 
}

.service-box {
  background-color: rgba(57, 60, 75, 0.8);
  border: 2px solid;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px;
  height: 100%;
}

.service-box h4 {
  margin-bottom: 20px; /* Espacio entre el título y la imagen */
}

.service-box img {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Opcional: para bordes redondeados en la imagen */
  margin-bottom: 20px; /* Espacio entre la imagen y el texto */
}

.service-box p {
  margin-top: auto; /* Empuja el texto hacia la parte inferior */
}
