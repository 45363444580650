.subTitle {
  width: 75%;
}

.titleHome {
  margin-top: 50px;
  margin-bottom: 24px;
}

.img-licencias {
  max-width: 15%;
  margin: 10px;
}

.box-container {
}

.service-box {
  background-color: rgba(57, 60, 75, 0.8);
  border: 2px solid;
  border-radius: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.btn.btn-getStarted {
  background-color: rgb(251, 162, 144);
  color: white;
  font-size: 25px;
  border-radius: 50px;
  border: none;
}

.btn.btn-getStarted:hover {
  background-color: rgb(251, 140, 120);
  border: none;
}

.btn.btn-getStarted:active {
  border-radius: 50px;
  background-color: rgb(251, 120, 100);
  border: none;
}

.btn.btn-box {
  background-color: rgb(251, 162, 144);
  color: white;
  border-radius: 50px;
  border: none;
}

.btn.btn-box:hover {
  background-color: rgb(251, 140, 120);
  border-radius: 50px;
  border: none;
}

.btn.btn-box:active {
  background-color: rgb(251, 120, 100);
  border-radius: 50px;
  border: none;
}

/* Ajustes específicos para dispositivos móviles */
@media (max-width: 768px) and (orientation: portrait){

  body.ios .bg-celular::before,
  body.ios .bg-miami-celu::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  body.ios .bg-celular::before {
    background-image: url('https://res.cloudinary.com/dos14xhdl/image/upload/v1729708067/bg-celular-languageHero_qztfbh.png');
  }

  body.ios .bg-miami-celu::before {
    background-image: url('https://res.cloudinary.com/dos14xhdl/image/upload/v1729708067/BackgroundLocationFinalCelu_iuswud.png');
  }

  body.android .bg-celular {
    background-image: url('https://res.cloudinary.com/dos14xhdl/image/upload/v1729708067/bg-celular-languageHero_qztfbh.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  body.android .bg-miami-celu {
    background-image: url('https://res.cloudinary.com/dos14xhdl/image/upload/v1729708067/BackgroundLocationFinalCelu_iuswud.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .service-box {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .btn-header,
  .header-getStarted {
    width: 100%;
    margin-bottom: 10px;
  }

  .titleHome {
    margin-top: 50px;
    margin-bottom: 0px;
  }

  .img-licencias {
    max-width: 25%;
    margin: 10px;
  }
}

/* Ajustes específicos para dispositivos móviles en modo horizontal */
@media (max-width: 768px) and (orientation: landscape) {

  body.ios .bg-celular::before,
  body.ios .bg-miami-celu::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  body.ios .bg-celular::before {
    background-image: url('../../extras/bg-celular-languageHero.png');
  }

  body.ios .bg-miami-celu::before {
    background-image: url('../../extras/BackgroundLocationFinalCelu.png');
  }

  body.android .bg-celular {
    background-image: url('../../extras/bg-celular-languageHero.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  body.android .bg-miami-celu {
    background-image: url('../../extras/BackgroundLocationFinalCelu.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .service-box {
    width: 48%;
    padding: 10px;
    margin: 0;
  }

  .btn-header,
  .header-getStarted {
    width: 100%;
    margin-bottom: 10px;
  }

  .titleHome {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .img-licencias {
    max-width: 15%;
    margin: 5px;
  }
}