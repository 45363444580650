.TitleContact{
  max-width: 50%; 
}

.title-contact {
  padding: 15px;
  text-align: center;
}

.transparent-bg-contact {
  background-color: rgba(57, 60, 75, 0.8);
  border: 2px solid white;
}

/*Form*/
.contact-info {
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column; /* Los elementos hijos se dispondrán en una columna */
  justify-content: space-around;
}

.form-label {
  color: #333;
}

.btn-primary {
  background-color: rgb(251, 162, 144);
  color: white;
  border-radius: 50px;
  white-space: nowrap;
  border: none;
}

.btn-primary:hover {
  background-color: rgb(251, 140, 120);
  border-radius: 50px;
  border: none;
}

.btn.btn-primary:active{
  border-radius: 50px;
  background-color: rgb(251, 120, 100);
  border: none;
}