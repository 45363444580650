body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  background-image: url('https://res.cloudinary.com/dos14xhdl/image/upload/v1729708066/BackgroundLanguageHeroFinal_bj0ijz.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth; 
}


/* Puedes usar las otras fuentes en elementos específicos */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Merriweather Sans', sans-serif;
}

.bg-celular{
  
}

.bg-miami-celu{
  
}

.handwritten {
  font-family: 'Edu AU VIC WA NT Hand', sans-serif;
}

.container {
  max-width: 100%;
  overflow-x: hidden;
  padding-left: 15px;
  padding-right: 15px;
}

/* Imágenes responsivas */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Ajustes de Navbar en móviles */
.navbar-toggler {
  margin-right: 0;
}

.navbar-collapse {
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/index.css o src/App.css */
@font-face {
  font-family: 'Reross Rectangular';
  src: url('./Fonts/fonnts.com-Reross_Rectangular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.font-reross {
  font-family: 'Reross Rectangular', sans-serif;
}

.h-color{
  color: rgb(251, 162, 144);;
}

.hTitle {
  font-size: 5vw;
}

.hTitle2{
  font-size: 4vw;
}

.hSubTitle {
  font-size: 3vw;
}

@media (max-width: 768px) {
  .hTitle {
    font-size: 9vw;
    text-align: center;
  }

  .hTitle2{
    font-size: 8vw;
  }
  
  .hSubTitle {
    font-size: 7vw;
  }
}