.transparent-bg {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
}

.titleNombre {
  max-width: 300px;
  min-width: 120px;
}

.btn.btn-header {
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 15px;
}

.btn.btn-header:hover {
  border: none;
  border-radius: 50px;
  background-color: rgb(251, 162, 144);
}

.btn.btn-header:active {
  border: none;
  border-radius: 50px;
  background-color: rgb(251, 120, 100);
}

.header-getStarted.btn {
  background-color: rgb(251, 162, 144);
  color: white;
  border-radius: 50px;
  white-space: nowrap;
  border: none;
  font-size: 15px;
}

.header-getStarted.btn:hover {
  background-color: rgb(251, 140, 120);
  border-radius: 50px;
  border: none;
}

.header-getStarted.btn:active {
  border-radius: 50px;
  background-color: rgb(251, 120, 100);
  border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.language-button.active {
  background-color: rgb(251, 162, 140);
  color: white;
}

@media (max-width: 768px) {
  .titleNombre {
    max-width: 175px;
  }
}