.bg-miami{
  position: relative;
  background-image: url('https://res.cloudinary.com/dos14xhdl/image/upload/v1729708067/BackgroundLocationFinal_hqwnyo.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-miami::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Ajusta la opacidad del negro según sea necesario */
  z-index: 1;
}

.bg-miami > * {
  position: relative;
  z-index: 2;
}