footer {
  margin-top: auto;
  color: white;
}

.transparent-bg {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
}

.icon-violet {
  color: rgb(196, 14, 141);
}

.icon-violet:hover {
  color: rgb(150, 14, 141);
}

.footer-info span {
  white-space: nowrap; /* Prevent spans from stacking */
  display: inline-block; /* Ensure spans are inline-block for better control */
}

/* Ensure proper padding and alignment on mobile devices */
@media (max-width: 768px) {
  .footer-info {
    text-align: center;
  }

  .redes-footer {
    text-align: center;
  }
}
